#slider-item-1 {
    background-image: url(../img/bg_1.jpg)
}

#slider-item-1 {

    background-image: url(../img/bg_2.jpg)
}

#slider-item-1 {
    background-image: url(../img/bg_3.jpg)
}

// TODO: clean
// backgroundImage: "url(images/bg_2.jpg)"
// backgroundImage: "url(images/bg_3.jpg)"
// backgroundImage: "url(images/bg_1.jpg)"
// backgroundImage: "url(images/bg_2.jpg)"