// background: url('../img/logo.png');

.navbar {
    position: fixed !important;
    height: 70px;
    width: 100%;

    @media (min-width: 992px) {
        padding-left: 5% !important;
    }

    & .open-menu {
        background: url('https://res.cloudinary.com/luisan/image/upload/v1589660574/hamburger-512_mxpqss.webp');
        width: 40px;
        height: 40px;
        background-size: cover;
        padding: 0 !important;
        position: absolute;
    }
}

.logo-img {
    background: url('https://res.cloudinary.com/luisan/image/upload/v1589659176/small_logo_ktvkqv.png');
    background-size: cover;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 3%;
}

.initials-img {
    background: url('https://res.cloudinary.com/luisan/image/upload/v1589660684/initials_hoq44e.png');
    background-size: cover;
    height: 40px;
    width: 140px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;

}

.navbar-brand {
    width: 50px;
    height: 50px;
    margin: auto 0 !important;
    position: absolute;
    right: 2%;
}

.navbar.show {
    background: white !important;
    transition: all 0.6s ease-in-out;
    right: 0;

    & .open-menu {
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }
}

.navbar.hide {
    background: transparent !important;
    transition: all 0.6s ease-in-out;
    right: 92vw;

    @media (max-width: 480px) {
        right: 80vw;
    }

    & .open-menu {
        opacity: 0.6;
        transition: all 0.6s ease-in-out;
    }
}


.navbar-toggler {
    display: none;
}

.dropdown-hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    position: absolute;
    right: -5px;
    padding: 12% 25%;
    background: white;
    overflow-y: hidden;

    .navbar-option {
        // background: #afd8fe;
        display: inline-block;
        border-radius: 8px;
        padding: 2%;
        box-sizing: border-box;

        & a {
            font-size: 1.1rem;
        }
    }

}

.dropdown-hamburger.hide {
    transition: height 0.6s ease-in-out, padding 0.6s ease-in-out;
    height: 0;
    padding: 0 25%;
}

.dropdown-hamburger.show {
    height: 110px;
    transition: height 0.6s ease-in-out, padding 0.6s ease-in-out;
}

.div-filler {
    height: 100%;
    width: 100%;
}

// .navbar-toggler.show {
//     display: inline-block;
//     // width: 100%;
//     opacity: 1;
//     transition: all 4s ease-in-out;

//     & span {
//         opacity: 1;
//         transition: all 4s ease-in-out;

//     }

// }

// .navbar-toggler.hide {
//     display: none;
//     // width: 0;
//     opacity: 0;
//     transition: all 4s ease-in-out;

//     & span {
//         transition: all 4s ease-in-out;

//     }

// }