@use './variables.scss';

.footer h1,
.footer h2,
.footer h3,
.footer h4,
.footer h5,
.footer h6,
.footer p {
  color: black;
  text-align: center;
}

// .highlight {
//     text-decoration: italic;
// }

.footer {
  height: 100vh;
  padding-bottom: 3vh;

  @media (max-width: 480px) {
    height: 100%;
    padding-bottom: 0;
  }

  & .container-fluid {
    @media (max-width: 480px) {
      height: 100% !important;
    }
  }
}

.footer-titles {
  & h3 {
    margin-bottom: 1.5%;
  }
}

.profesional-icon,
.pequeño-icon {
  height: 13vw;
  width: 13vw;
  margin: 3vw;
  border: 1px solid white;
  margin-bottom: 0;
}

.icon {
  bottom: 0;
}

.icon h4 {
  font-size: 4rem;
  margin-bottom: 20px;
}

.contact-info {
  position: absolute;
  bottom: -2%;
  background: $blue;
  box-shadow: 6px 7px 11px -3px $shadow-blue;
  z-index: 3;
  padding: 10px 30px 4px 30px;
  border-radius: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40vw;

  & h4,
  h5 {
    color: white;
    text-align: center;
  }

  & h5 {
    font-size: 1.4rem;
  }

  .white-hover-lbrown {
    color: white;

    &:hover {
      color: $light-brown;
    }
  }
}

.py-1.d-block {
  display: none !important;
}

.ftco-footer-social li a:hover {
  color: #fff;
}

.footer .price-icons {
  display: none !important;
}

.price-cards {
  display: flex;
  justify-content: center;
  margin-top: 45px;
}

.price-card {
  box-shadow: 2px 9px 14px 1px $shadow-blue-lighter;
  height: 320px;
  width: 220px;
  margin: 20px 5%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $gray-transparent;
  border-radius: 20px;
  padding: 37px 0;

  &.free-offer h5 {
    padding: 0 15%;

    @media (max-width: 480px) {
      padding: 0 20%;
    }
  }

  & h3,
  h5 {
    text-align: center;
    color: black;
  }

  & h3 {
    font-size: 2.1rem;
    background: rgba(115, 95, 64, 1);
    border-radius: 100%;
    height: 107px;
    width: 107px;
    color: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    padding: 0px 5px 3px 3px;
  }

  & .tiny-text {
    font-size: 10px;
    margin: auto 0 38.5%;
  }
}

.price-card-cta {
  background-color: rgba(255, 232, 194, 0.8);
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 25%;
  display: flex;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  color: white;
  background-color: $blue;
  opacity: 0.95;
  align-items: center;
  padding-bottom: 7px;
  transition: color 0.15s ease-in-out;
  // z-index: 10;

  // &:hover,
  &:hover {
    // color: #ffe8c2;
    color: $light-brown;
    // background-color: rgba(81, 118, 152, 0.95);
    background-color: $hover-blue;
    transition: all 0.3s ease-in-out;
  }
}

.cta-phonecall,
.cta-email {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.cta-email {
  display: inline-block;
}

.cta-phonecall {
  display: none;
}

@media (max-width: 480px) {
  .cta-email {
    display: none;
  }

  .cta-phonecall {
    display: inline-block;
  }
}

.fa-linkedin {
  content: url("../img/linkedin_white.png");
  opacity: 1;
  width: 58%;

  &:hover {
    content: url("../img/linkedin_lbrown.png");
  }
}

.fa-facebook {
  content: url("../img/facebook_white.png");
  opacity: 1;
  width: 58%;

  &:hover {
    content: url("../img/facebook_lbrown.png");
  }
}

.fa-instagram {
  content: url("../img/instagram_white.png");
  opacity: 1;
  width: 58%;

  &:hover {
    content: url("../img/instagram_lbrown.png");
  }
}

.footer-nav {
  flex-basis: "auto";
  opacity: 0;
}

@media (max-width: 480px) {
  .footer.vh-100 {
    height: 150vh !important;

    .justify-content-end {
      justify-content: flex-start !important;
    }
  }

  .price-cards {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 10%;
  }

  .footer .contact-info {
    bottom: 3%;
    width: 85vw;
    padding: 10px;

    h4 {
      font-size: 20px;
    }

    & .justify-content-end {
      justify-content: flex-end !important;
    }
  }
}
